<template>
    <div class="login">
		<div class="login_head">
			<div class="lh_title">
				<span>智慧生产车间</span>
			</div>
			<div class="lh_desc">
				<span>欢迎您！</span>
			</div>
		</div>
		<div class="login_content">
			<div class="lc_input">
				<input placeholder="请输入名字" v-model="name"/>
			</div>
			<div class="lc_input" style="margin-top:0.8rem;">
				<input placeholder="请输入电话号码" v-model="passport"/>
			</div>
			<div class="lc_btn" @click="login">
				<span>登录</span>
			</div>
			<div class="register" v-if="isCreat">
				还没有账号？<span @click="toRegister">点击注册</span>
			</div>
		</div>
		<div class="version">{{version}}</div>
    </div>
</template>
<script>
import until from '@/api/until.js'
import studio_login_api from '@/api/teaching_studio_no_login.js'
import tool_api from '@/api/teaching_studio_tool.js'
import studio_api from '@/api/teaching_studio_teaching_studio_api.js'
import md5 from 'js-md5'
import { get } from 'http'

export default {
	name: 'login',
	data(){
		return{
			version:"1.0.6",
			salt:"",
			passport:"",
			name:"",
			login_qrcode:'',
			isCreat:'',
			phone_number:'',
		}
	},
	components: {
		
	},
	mounted(){
		this.getSalt();
		this.getCouldCreat()
		this.phone_number = this.$route.query.phone_number
		this.name = this.$route.query.name
		this.login_qrcode=this.$route.query.qrcode
		document.title="登录及绑定"

		
	},
	methods:{
		//获取表单验证码
		async getSalt(){
			let computer_code=until.getCookie("guid").substring(0,6)
			let data=await tool_api.ToolForm_saltGet({computer_code});
			if(data.errcode==0){
				this.salt=data.other;
			}
		},
		toRegister(){
			if(this.isCreat){
				this.$router.push("/register")
			}else{
				this.$toast('尚不具备注册条件')
			}
			
		},
		//用户绑定
		
		async getCouldCreat(){
			let app_id=this.$root.app_id
			let data=await studio_login_api.Api_nologinWechatCouldSelf_createGet({app_id});
			if(data.errcode==0){
				this.isCreat=data.other
			}
		},
		
		// 微信环境
		// Pack_change
		async login(){
			let app_id=this.$root.app_id
			let computer_code=until.getCookie("guid").substring(0,6)
			let passport='';
			if(this.phone_number){
				passport = this.phone_number
			}else{
				passport = this.passport
			}
			let name=this.name
			let salt=this.salt;
			this.$toast.loading({
				message: "加载中...",
				forbidClick: true,
				duration: 0,
			});
			let data=await studio_login_api.Api_nologinLoginWechat_platformLogin_passportPost({app_id,computer_code,passport,name,salt});
			if(data.errcode==0){
				this.$toast.clear();
				this.$toast("绑定成功")
		
				this.$router.push("/")
			}
			else{
				this.$toast.clear();
				this.getSalt();
			}
		},
	
		// 本地环境
		// async login(){
		// 	let app_id=this.$root.app_id
		// 	let computer_code=until.getCookie("guid").substring(0,6)
		// 	let passport="13327970964";
		// 	let md5_password=md5("123456").toUpperCase()
		// 	let name=this.name
		// 	let salt=this.salt;
		// 	this.$toast.loading({
		// 		message: "加载中...",
		// 		forbidClick: true,
		// 		duration: 0,
		// 	});
		// 	let data=await studio_login_api.Api_nologinLoginPassportPost({app_id,computer_code,passport,md5_password,salt});
		// 	if(data.errcode==0){
		// 		this.$toast.clear();
		// 		this.$toast("绑定成功")

	
		// 		this.$router.push("/")
		// 	}else{
		// 		this.$toast.clear();
		// 		this.getSalt();
		// 	}
		// },

	}
}
</script>
<style scoped>
	.login{
		width: 100%;
		height: 100vh;
		background: #FFFFFF;
	}
	.login_head{
		width: 100%;
		height: 400px;
		background: url('/static/imgs/login_head.png');
		background-size:100% 100%; 
		display: inline-block;
	}
	.lh_title{
		width: 100%;
		font-size: 32px;
		line-height: 45px;
		color: #FFFFFF;
		font-weight: 500;
		padding-left: 60px;
		margin-top: 120px;
		box-sizing: border-box;
	}
	.lh_desc{
		width: 100%;
		font-size: 40px;
		color: #FFFFFF;
		line-height: 56px;
		margin-top: 16px;
		padding-left: 60px;
		box-sizing: border-box;
	}
	.login_content{
		width: 100%;
		position: relative;
		margin-top: -90px;
		border-radius: 40px 40px 0px 0px;
		background: #FFFFFF;
		display: inline-block;
	}
	.lc_input{
		width: 100%;
		padding: 0 90px;
		box-sizing: border-box;
		margin-top: 120px;
	}
	.lc_input input{
		height: 88px;
		background: #F5F5F5;
		border-radius: 44px;
		border: 0px;
		outline: none;
		text-align: center;
		width: 100%;
		font-size: 28px;
	}
	.lc_btn{
		width: calc(100% - 180px);
		height: 80px;
		background: linear-gradient(90deg, #BCEA5A 0%, #75C969 100%);
		border-radius: 40px;
		margin-left: 90px;
		margin-top: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 32px;
		color: #FFFFFF;
		font-weight: 500;
	}
.register{
	font-size: 28px;
	color: #999999;
	line-height: 37px;
	text-align: center;
	margin-top: 80px;
}
.register span {
	color: #75C969;
}
.version{
	width: 100%;
	font-size: 28px;
	color: #999999;
	text-align: center;
	margin-top: 50%;
}
</style>
